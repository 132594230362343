<template>
  <section class="d-flex flex-column">
    <PageHeader
      :title="$t('breadcrumb.Reports')"
      :sub-title="$t('sparkmembers.reports.views.list.index.sub-title')"
      :back-text="$t('breadcrumb.ProductDashboard')"
      back-route="ProductDashboard"
    />
    <hsLoading v-if="isLoading" class="flex-fill" />

    <template v-else>
      <section class="container-fluid">
        <div class="row mt-4 md-5 px-4">
          <div class="col-md-12 p-0">
            <div class="px-4 py-2 bg-white rounded-lg shadow-sm">
              <div class="row" id="card-title-container">
                <div class="col-md-2">
                  <b-img-lazy class="video-image" :src="videoImage"></b-img-lazy>
                </div>
                <div class="col-md-5">
                  <h5 id="info-card-title" class="ml-3">
                    {{ $t('sparkmembers.reports.views.list.index.card.title') }}
                  </h5>
                  <p id="info-card-subtitle" class="ml-3 mt-4 font-size-sm">
                    {{ $t('sparkmembers.reports.views.list.index.card.sub-title') }}
                  </p>
                </div>
                <div class="col-md-5 text-right">
                  <hs-button
                    id="export-button"
                    class="px-3 py-2"
                    :disabled="reportLoading"
                    @click="createReport"
                    type="button"
                  >
                    <hs-icon icon="download" />
                    {{ $t('sparkmembers.reports.views.list.index.button') }}
                  </hs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row p-4 mt-3">
          <div class="col-md-3">
            <b-img-lazy class="coffee-image p-2" :src="coffeeImage"></b-img-lazy>
          </div>
          <div class="col-md-6" id="async-warning-container">
            <h5 id="async-warning-title" class="">
              {{ $t('sparkmembers.reports.views.list.index.async-warning.title') }}
            </h5>
            <p id="async-warning-subtitle" class="mt-4 font-size-sm">
              {{ $t('sparkmembers.reports.views.list.index.async-warning.sub-title1') }}
            </p>
            <p id="async-warning-subtitle" class="mt-4 font-size-sm">
              {{ $t('sparkmembers.reports.views.list.index.async-warning.sub-title2') }}
            </p>
          </div>
          <div class="col-md-3">
            <div class="row tips-herospark pl-4 py-3">
              <h5 class="tips-herospark-title" v-html="$t('sparkmembers.reports.views.list.index.hero-tips.title')" />
            </div>
            <div class="row tips-herospark-body pl-4 pr-3 py-3">
              <h5 class="font-size-sm font-weight-bold">
                {{ $t('sparkmembers.reports.views.list.index.hero-tips.sub-title') }}
              </h5>
              <p class="mt-2 font-size-sm">{{ $t('sparkmembers.reports.views.list.index.hero-tips.description') }}</p>
            </div>
          </div>
        </div>
      </section>
    </template>
  </section>
</template>

<script>
import debug from 'debug';
import reportService from '@/sparkmembers/services/report';
import { hsLoading } from '@/components';
import { mapState, mapActions } from 'vuex';
import PageHeader from '@/components/_structures/PageHeader';
import ToastHelper from '@/shared/helpers/toast';

const logging = debug('hs:reports');

export default {
  components: { PageHeader, hsLoading },
  data() {
    return {
      isLoading: true,
      currentPage: 1,
      videoImage: require('@/assets/images/sign_up/illustration-signup-video.svg'),
      coffeeImage: require('@/assets/images/SparkMembers/Reports/coffee.svg'),
      reportLoading: false,
    };
  },
  mounted() {
    this.checkReportProcessing();
  },
  computed: {
    ...mapState({
      school: state => state.school.selectedSchool,
      product: state => state.product.selectedProduct,
    }),
  },
  methods: {
    async createReport() {
      try {
        this.reportLoading = true;
        await reportService.create(this.product.course.id, this.school.id, 'CourseReport');
        ToastHelper.successMessage(this.$t('sparkmembers.reports.views.list.index.toast-success'));
      } catch (error) {
        ToastHelper.dangerMessage(this.$t('sparkmembers.reports.views.list.index.toast-error'));
      } finally {
        await this.checkReportProcessing();
      }
    },
    async checkReportProcessing() {
      try {
        const res = await reportService.checkProcessing(this.school.id);
        this.reportLoading = res;
        this.isLoading = false;
      } catch (error) {
        logging('Erro ao checar status dos reports, id da escola: %d', this.school.id);
      }
    },
  },
};
</script>

<style lang="scss">
.tips-herospark {
  background-color: #b3dafe;
}
.tips-herospark-body {
  background-color: #ffffff;
}
.tips-herospark-title {
  color: #0c2678;
}
.video-image {
  height: 170px;
}
#card-title-container {
  align-items: center;
}
#info-card-title {
  font-size: 20px;
  font-weight: 600;
}
#info-card-subtitle {
  color: #6f6f6f;
}
#export-button {
  background-color: var(--color-purple-500);
}
#export-button:disabled {
  background-color: #cfcfcf;
}
#async-warning-container {
  align-items: center;
}
#async-warning-title {
  font-size: 20px;
  font-weight: 600;
}
#async-warning-subtitle {
  color: #3f3f3f;
}
</style>
