import axiosXavier from '../../services/axios';

export default {
  getAll: school_id => axiosXavier.get(`schools/${school_id}/reports`).then(res => res.data),
  get: (school_id, id) => axiosXavier.get(`schools/${school_id}/reports/${id}`).then(res => res.data),
  create: (course_id, school_id, report_type) =>
    axiosXavier
      .post(`schools/${school_id}/reports`, { report: { course_id, school_id, type: report_type } })
      .then(res => res.data),
  checkProcessing: school_id => axiosXavier.get(`schools/${school_id}/reports/check_processing`).then(res => res.data),
};
